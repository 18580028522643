@import 'layouts/Admin/variable';

.gradient-layout__body__content {
  width: 100%;
  z-index: 0;
  background: #f3f3f5;
  padding: 15px 30px;
  min-height: calc(100vh - #{$nav-height});
  border-top-left-radius: 20px;
  margin-top: 70px;
}
