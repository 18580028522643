@import './mixin';
@import 'scss/mixins/dimention';
@import 'scss/mixins/text';
@import 'scss/mixins/spacing';
@import 'scss/functions/text';
@import 'scss/var/color';

.custom-ant-popup {
  $popup-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0;
  .ant-modal-content {
    border-radius: $popup-radius;
    width: 100%;
    .ant-modal-header {
      border-top-left-radius: $popup-radius;
      border-top-right-radius: $popup-radius;
    }

    .ant-modal-close {
      top: 16px;
      right: 16px;

      .ant-modal-close-x {
        width: auto;
        height: auto;
        line-height: auto;
      }
    }

    .ant-modal-body {
      .title {
        color: $page-title-font-label-color;
        @include fontBolt;
        font-size: 24px;
      }

      .sub-title {
        color: $table-headers-color;
        @include fontBolt;
        font-size: 18px;
        margin: 8px 0;
      }
    }
  }
}
