@import './../../scss/var/color';

.authen-page {
  height: 100vh;
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  content: '';
  display: flex;

  .authen-page__left {
    background: $link-color;
    background: -webkit-linear-gradient(-180deg, $link-color, #50e3c2);
    background: linear-gradient(-180deg, $link-color, #50e3c2);
    width: 50%;
    height: 100%;

    .authen-page__left__line1 {
      padding-left: 40px;
      margin-bottom: -60px;
    }

    .authen-page__left__line2 {
      display: flex;
      justify-content: flex-end;
      padding-right: 30px;
    }

    .authen-page__left__line3 {
      padding-left: 40px;
    }

    .authen-page__left__logo {
      padding: 35px 40px 0px 40px;
    }
    .authen-page__left__logo__p {
      color: #ffffff;
      font-family: 'NotoSans-Regular', sans-serif;
    }
  }

  .authen-page__right {
    padding: 200px 0;
    width: 50%;
    height: 100%;
  }
}
