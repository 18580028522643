$base-font-color: #20253a;
$page-title-font-label-color: #676767;
$table-headers-color: #74798c;
$active-approved-labels-color: #00b584;
$pending-label-color: #f5a623;
$rejected-declined-labels-color: #d0021b;
$link-color: #4a90e2;
$input-text-color: #74798c;
$item-selected-background-color: #d3dde5;
$box-shadow-color: #00000029;
$disable-btn-background-color: #cfd0d7;
$disable-btn-text-color: #aaadb8;
$white: #ffffff;
$disable-text-field: #efefef4d;
$title-color: #707070;
$draft-color: #008cfa;

$status-orange-color: #ff6200;
$status-green-color: #00b584;
$status-red-color: #d0021b;
$status-gray-color: #aaadb8;
