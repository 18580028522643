@import 'scss/var/color';
@import 'scss/mixins/dimention';
@import 'scss/mixins/common';
@import 'scss/mixins/spacing';
@import 'scss/mixins/text';

.logout-confirm-popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .logout-confirm-popup__wrap {
    width: 100%;
    height: 100%;
    position: relative;

    .logout-confirm-popup_wrap__overlay {
      width: 100%;
      height: 100%;
      background: $base-font-color 0% 0% no-repeat padding-box;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
      opacity: 0.8;
    }

    .logout-confirm-popup_wrap__content {
      position: absolute;
      z-index: 30;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 554px;
      height: 367px;
      box-shadow: 0px 3px 6px #f2f2f229;
      border: 1px solid #707070;
      border-radius: 20px;
      background: #fff;
      box-sizing: border-box;
      padding: 48px 38px;

      .logout-confirm-popup_wrap__content__logo {
        text-align: center;
        margin-bottom: 20px;
        height: 74px;
        img {
          width: 74px;
          height: 74px;
        }
      }

      .logout-confirm-popup_wrap__content__question {
        .logout-confirm-popup_wrap__content__question__title {
          color: $base-font-color;
          font-size: 24px;
          @include fontMedium;
          text-align: center;
        }
        .logout-confirm-popup_wrap__content__question__detail {
          @include fontRegular;
          width: 305px;
          margin: 0 auto;
          text-align: center;
          color: $table-headers-color;
          font-size: 16px;
          margin-top: 12px;
        }
      }

      .logout-confirm-popup_wrap__content__btn {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;

        button {
          width: 225px;
          height: 48px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 16px;
          border-radius: 12px;
          @include fontMedium;
          box-shadow: 0px 1px 2px #00000052;
          box-sizing: border-box;

          &.logout-confirm-popup_wrap__content__btn__cancel {
            border: 1px solid #474955;
            background: #ffffff;
            color: #474955;
          }

          &.logout-confirm-popup_wrap__content__btn__logout {
            border: none;
            background: $link-color;
            color: #ffffff;
          }
        }
      }
    }
  }
}
