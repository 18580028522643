@import './variable';

.gradient-layout {
  @import './NavBar/style.scss';

  .gradient-layout__body {
    .gradient-layout__body__left {
      width: $menu-expand-width;
      height: 100vh;
      float: left;
      @import './Menu/style.scss';

      &.-collapsed-menu {
        width: $menu-collapsed-width;
      }
    }

    .gradient-layout__body__right {
      width: calc(100% - #{$menu-expand-width});
      float: right;
      box-sizing: border-box;
      @import './Content/style.scss';

      &.-collapsed-menu {
        width: calc(100% - #{$menu-collapsed-width});
      }

      .gradient-layout__body__right__hidden-nav-spacing {
        height: $nav-height;
      }
    }

    &::after {
      clear: both;
    }
  }

  &.-blank-layout {
    .gradient-layout__nav,
    .gradient-layout__body__menu {
      display: none;
    }

    .gradient-layout__body {
      .gradient-layout__body__left {
        display: none;
      }

      .gradient-layout__body__right {
        width: 100%;

        .gradient-layout__body__right__hidden-nav-spacing,
        .gradient-layout__body__right__radius-area {
          display: none;
        }

        .gradient-layout__body__content {
          background: #fff;
          padding: initial;
          min-height: 100vh;
          margin-top: 0px;
          border-top-left-radius: 0px;
        }
      }
    }
  }
}
