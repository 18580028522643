.-disable,
.disable {
  cursor: no-drop !important;
  opacity: 0.6;
}

.inline-flex {
  display: inline-flex;
}

.-hidden {
  display: none;
}

.-cursor-pointer {
  cursor: pointer;
}
