@import 'scss/var/color';
@import 'scss/mixins/dimention';
@import 'scss/mixins/common';
@import 'scss/mixins/spacing';
@import 'scss/mixins/text';

.custom-ant-checkbox {
  .ant-checkbox-wrapper:hover,
  &:hover {
    .ant-checkbox-inner {
      border-color: transparent;
      display: inline-flex;
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: transparent;
    display: inline-flex;
  }

  .ant-checkbox {
    @include setDimention(width, 20);
    @include setDimention(height, 20);
    padding: 2px;
    box-sizing: border-box;

    .ant-checkbox-inner {
      background: #fff;
      border-radius: 4px;
      display: inline-flex;
      border-color: #fff;
      transition: initial;
      &::after {
        display: none;
      }
    }

    &.ant-checkbox-checked {
      &::after {
        border-color: #707070;
        visibility: visible;
        animation: initial;
      }

      .ant-checkbox-inner {
        background: #707070;
      }
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #707070;
      border-radius: 2px;

      content: '';
      visibility: visible;
    }
  }

  &.-has-error {
    .ant-checkbox {
      &.ant-checkbox-checked {
        &::after,
        .ant-checkbox-inner {
          border-color: red;
        }
      }

      &::after {
        border-color: red;
      }
    }
  }
}
