@import 'scss/var/color';
@import 'scss/mixins/dimention';
@import 'scss/mixins/common';
@import 'scss/mixins/spacing';
@import 'scss/mixins/text';

.mbtn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  @include setFontSize(16);
  @include fontRegular;
  height: 48px;
  box-sizing: border-box;
  padding: 0 20px;
  border-radius: 8px;
  background: $link-color;
  cursor: pointer;
  border: none;
  user-select: none;

  img {
    margin-right: 16px;
    width: 24px;
    height: 24px;
  }

  &.-disable,
  &:disabled {
    cursor: no-drop;
    opacity: 0.5;

    span {
      &.anticon {
        margin-right: 16px;
      }
    }
  }

  &:active,
  &:focus {
    outline: none;
  }

  &.-black {
    background: #25262c;
    color: #fff;
  }

  &.-link {
    padding: 0;
    height: fit-content;
    background: transparent;
    color: $link-color;
    text-decoration: underline;
    cursor: pointer;
  }
}
