@import 'scss/var/color';
@import 'scss/mixins/dimention';
@import 'scss/mixins/common';
@import 'scss/mixins/spacing';
@import 'scss/mixins/text';

body {
  @include fontRegular;
  background: #fff;
}
