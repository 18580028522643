@import 'layouts/Admin/variable';
@import 'scss/var/color';

.gradient-layout__body__menu {
  $padding-h: 27px;

  width: $menu-expand-width;
  height: calc(100vh);
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;

  padding: 0 $padding-h;
  box-sizing: border-box;
  z-index: 1;

  .gradient-layout__body__menu__item--wrap {
    &:not(:last-child) {
      margin-bottom: 23px;
    }
    user-select: none;
    $logo-width: 32px;

    .gradient-layout__body__menu__item {
      display: flex;
      align-items: center;
      border-radius: 23px;
      height: 32px;
      box-sizing: border-box;
      padding: 0 12px 0 15px;
      cursor: pointer;

      user-select: none;

      .gradient-layout__body__menu__item__icon {
        display: inline-flex;
        margin-right: 8px;

        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .gradient-layout__body__menu__item__text {
        display: inline-flex;
        font-size: 14px;
        color: $table-headers-color;

        white-space: nowrap;
      }

      &.-active {
        background: transparent
          linear-gradient(180deg, $link-color 0%, #50e3c2 100%) 0% 0% no-repeat
          padding-box;

        .gradient-layout__body__menu__item__text {
          color: #fff;
        }
      }

      &.-collapsed-menu {
        transform: rotate(180deg);

        .gradient-layout__body__menu__item {
          justify-content: center;
          .gradient-layout__body__menu__item__icon {
            width: auto;
            height: auto;
          }
        }
      }
    }

    &.-branch {
      padding: 0;
      background: transparent;
      height: $nav-height;
      align-items: center;

      .gradient-layout__body__menu__item {
        height: 100%;
        padding: 0;
        background: transparent;

        .gradient-layout__body__menu__item__icon {
          width: auto;
          height: auto;
          svg {
            width: $logo-width;
          }
        }
      }
    }
  }

  &.-collaped {
    width: $menu-collapsed-width;

    @keyframes hide {
      from {
        width: 100%;
      }

      to {
        width: 32px;
      }
    }

    .gradient-layout__body__menu__branch {
      animation-name: hide;
      animation-duration: 1s;
      animation-delay: 0s;
      animation-fill-mode: both;

      .gradient-layout__body__menu__branch__text {
        display: none;
        margin-left: 0;
      }
    }

    .gradient-layout__body__menu__item {
      padding: 0;
      height: 32px;
      border-radius: 50%;
      display: inline-flex;

      animation-name: hide;
      animation-duration: 1s;
      animation-delay: 0s;
      animation-fill-mode: both;

      .gradient-layout__body__menu__item__icon {
        justify-content: center;
        align-items: center;
        display: inline-flex;
        text-align: center;
        width: 100%;
        height: 100%;
        margin-right: 0;
        position: relative;

        svg {
          display: inline-flex;
          width: 21px;
          height: 21px;
        }
      }

      .gradient-layout__body__menu__item__text {
        display: none;
      }
    }
  }

  .gradient-layout__body__menu__cached-img-area {
    position: fixed;
    width: 1px;
    height: 1px;
    overflow: hidden;
    top: -1000px;
    left: -1000px;
  }
}
