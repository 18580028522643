.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover {
  border-color: transparent;
  outline: none;
  box-shadow: none;
}

.ant-form {
  legend {
    border-bottom: none;
  }
}
