@import './mixin';
@import 'scss/mixins/dimention';
@import 'scss/mixins/text';
@import 'scss/mixins/spacing';
@import 'scss/functions/text';
@import 'scss/var/color';

.ant-table-custom {
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background: inherit;
  }

  tr {
    > td {
      &:first-child {
        position: relative;
        &::before {
          content: '';
          width: 3px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: block;
        }
      }
    }

    &.table-row-light {
      background-color: #fff;
      > td {
        &:first-child {
          &::before {
            opacity: 1;
          }
        }
      }
    }

    &.table-row-dark {
      background-color: #f3f3f5;
      > td {
        &:first-child {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  &.td-vertical-align-top {
    tr {
      td {
        vertical-align: top;
      }
    }
  }

  thead {
    background: #fff;

    tr {
      background: #fff;

      th {
        user-select: none;
        background: #fff;
        @include fontMedium;

        &.ant-table-cell {
          font-size: 14px;
          color: #9b9eac;
          user-select: none;
          vertical-align: top;
        }

        .ant-table-column-sorter {
          display: inline-flex;
          height: 22px;
          align-items: center;

          .ant-table-column-sorter-inner {
            span {
              &.active {
                color: $table-headers-color;
              }
            }
          }
        }

        .ant-table-column-title {
          font-size: 14px;
          color: #9b9eac;
          user-select: none;
          margin-right: 10px;
        }
        &:not(.-column-content-right) {
          .ant-table-column-sorters {
            justify-content: flex-start;
            align-items: initial;
          }
          .ant-table-column-title {
            flex: initial;
          }
        }

        &.ant-table-column-sort {
          background: transparent;
        }

        &.-column-content-right {
          @include custom-antd-table-column-align-right();
        }
      }
    }
  }

  tbody {
    tr {
      &.ant-table-row-selected {
        td {
          background: transparent;
        }
      }
      td {
        &.ant-table-column-sort {
          background: transparent;
        }

        color: $base-font-color;
        font-size: 14px;
        a {
          color: $link-color;
          text-decoration: underline;
        }

        .record-status {
          text-transform: uppercase;
          font-size: 14px;
          @include fontMedium;

          &.-active,
          &.-approved,
          &.-re-recon,
          &.-add {
            color: $active-approved-labels-color;
          }
          &.-pending,
          &.-manual-check,
          &.-untagged,
          &.-id-review,
          &.-edit {
            color: $status-orange-color;
          }
          &.-inoperative,
          &.-dormant,
          &.-closed {
            color: $status-gray-color;
          }
          &.-debit_block,
          &.-blocked {
            color: $status-red-color;
          }
          &.-flagged,
          &.-rejected,
          &.-kycreject,
          &.-inactive {
            color: $rejected-declined-labels-color;
          }

          &.-expired {
            color: $table-headers-color;
          }
          &.-deactivated {
            color: $status-gray-color;
          }

          &.-invited {
            color: $draft-color;
          }
        }

        &.-column-content-right {
          text-align: right;
          box-sizing: border-box;

          &.-no-sorting {
            padding-right: 40px;
          }
        }

        .-no-break-line {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 100%;
          align-items: center;
        }
      }

      &:not(.ant-table-placeholder) {
        &:hover {
          background: #d4d5d9 !important;
          td {
            background: #d4d5d9 !important;
          }
        }
      }

      &.ant-table-placeholder {
        background: #fff;
      }
    }
  }

  ul {
    &.ant-pagination {
      padding: 0 16px;
      box-sizing: border-box;

      li {
        border-color: transparent;

        > button {
          &.ant-pagination-item-link {
            border-color: transparent;
            svg,
            a {
              font-size: 12px;
            }
          }
        }

        &.ant-pagination-item-active {
          background: $link-color;
          border-color: $link-color;
          a {
            color: #fff;
            font-size: 12px;
          }
        }

        &.ant-pagination-total-text {
          float: left;
        }
      }
    }
  }

  &.-no-scroll-y {
    .ant-table-body {
      overflow: auto !important;
    }

    .ant-table-body::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background: #ffffff;
    }

    .ant-table-body::-webkit-scrollbar-thumb {
      background: #aaadb8;
      border-radius: 5.5px;
    }
  }
}

.table-summary {
  tr.ant-table-row.ant-table-row-level-0 {
    background: RGBA(211, 221, 229, 0.5) !important;
    button {
      display: none;
    }
  }
}

.ant-table-custom-paging {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px 15px;

  .ant-table-custom-paging__left {
    color: $table-headers-color;
    .ant-table-custom-paging__left__bold {
      @include fontMedium;
      color: #474955;
    }
  }

  .ant-table-custom-paging__right {
    display: flex;
    justify-content: flex-end;

    ul {
      display: inline-flex;
      &.ant-pagination {
        padding: 0 16px;
        box-sizing: border-box;

        li {
          border-color: transparent;
          > button {
            &.ant-pagination-item-link {
              border-color: transparent;
              svg,
              a {
                font-size: 12px;
              }
            }
          }

          &.ant-pagination-item-active {
            background: $link-color;
            border-color: $link-color;
            a {
              color: #fff;
              font-size: 12px;
            }
          }

          &.ant-pagination-total-text {
            float: left;
          }
          &.ant-pagination-item {
            font-size: 12px;

            &.ant-pagination-disabled {
              color: #d9d9d9;
              &:hover {
                border-color: #d9d9d9;
              }
            }
          }
        }
      }
    }
  }
}

.table-free-text-search {
  display: flex;
  padding-right: 15px;
  box-sizing: border-box;
  height: 80px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
