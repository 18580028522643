@font-face {
  font-family: 'NotoSans-Regular';
  src: url('./fonts/NotoSans-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'NotoSans-Bold';
  src: url('./fonts/NotoSans-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'NotoSans-SemiBold';
  src: url('./fonts/NotoSans-SemiBold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'NotoSans-BoldItalic';
  src: url('./fonts/NotoSans-BoldItalic.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'NotoSans-Italic';
  src: url('./fonts/NotoSans-Italic.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'NotoSans-Medium';
  src: url('./fonts/NotoSans-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'NotoSans-SemiBold';
  src: url('./fonts/NotoSans-SemiBold.ttf') format('truetype'); /* Safari, Android, iOS */
}
