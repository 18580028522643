@import 'scss/mixins/dimention';
@import 'scss/mixins/text';
@import 'scss/mixins/spacing';
@import 'scss/functions/text';
@import 'scss/var/color';

.ant-notification {
  .ant-notification-notice {
    $error-color: $rejected-declined-labels-color;
    $success-color: $active-approved-labels-color;

    &.ant-notification-notice-error {
      border: 1px solid $error-color;
      border-radius: 12px;
      background: #fdf5f6;

      .ant-notification-notice-with-icon {
        svg {
          color: $error-color;
        }
      }

      .ant-notification-notice-message {
        @include setFontSize(16);
        color: $error-color;
        @include fontBolt;
      }

      .ant-notification-notice-description {
        color: $table-headers-color;
      }
    }

    &.ant-notification-notice-success {
      border: 1px solid $success-color;
      border-radius: 12px;
      background: #f5fdf9;

      .ant-notification-notice-with-icon {
        svg {
          color: $success-color;
        }
      }

      .ant-notification-notice-message {
        @include setFontSize(16);
        color: $success-color;
        @include fontBolt;
      }

      .ant-notification-notice-description {
        color: $table-headers-color;
      }
    }
  }
}
