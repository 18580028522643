.custom-antd-select {
  width: 100%;

  &.ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 8px;
      height: 36px;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
    }
  }
}

.ant-select-item-option.custom-antd-select-option {
  height: 48px;
  display: flex;
  align-items: center;
  color: #74798c;

  &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #a9bece;
    color: #74798c;
    font-weight: normal;
  }
}
