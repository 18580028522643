@import 'layouts/Admin/variable';
@import 'scss/var/color';

.gradient-layout__nav {
  width: calc(100% - #{$menu-expand-width});
  height: $nav-height;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  transition: all linear 0s;

  .gradient-layout__nav__left {
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;

    .gradient-layout__nav__left__shape-round {
      position: absolute;
      top: 35px;
      left: -30px;

      @keyframes hide {
        from {
          &::before,
          &::after {
            background: transparent;
          }
        }

        to {
          ::before {
            background: #fff;
          }

          ::after {
            background: #f3f3f5;
          }
        }
      }
      transition-delay: 0.3s;

      &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        background: #fff;
        z-index: 1;
        border-bottom-right-radius: 20px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 20px;
        height: 20px;
        background: #f3f3f5;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
        z-index: 2;
      }
    }
  }

  .gradient-layout__nav__right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    .gradient-layout__nav__right__user {
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 32px;
        height: 32px;
      }

      .gradient-layout__nav__right__user__name {
        color: $table-headers-color;
        font-size: 14px;
        margin-left: 7px;
      }
    }
  }

  &.-collapsed {
    width: calc(100% - #{$menu-collapsed-width});
  }
}
