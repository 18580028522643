@use "sass:math";
@import 'scss/functions/text';

@mixin fontSize($fontSize) {
  font-size: ($fontSize * 10) + px;
  font-size: $fontSize + rem;
}

@mixin getFontSize($pixcel) {
  font-size: ($pixcel) + px;
  font-size: ($pixcel / 16) + rem;
}
@mixin setFontSize($pixcel) {
  font-size: ($pixcel) + px;
  font-size: ($pixcel / 16) + rem;
}

@mixin fontRegular {
  font-family: 'NotoSans-Regular', sans-serif;
}

@mixin fontBolt {
  font-family: 'NotoSans-Bold', sans-serif;
}

@mixin fontSemiBold {
  font-family: 'NotoSans-SemiBold', sans-serif;
}

@mixin fontBoldItalic {
  font-family: 'NotoSans-BoldItalic', sans-serif;
}

@mixin fontItalic {
  font-family: 'NotoSans-Italic', sans-serif;
}

@mixin fontMedium {
  font-family: 'NotoSans-Medium', sans-serif;
}

@mixin lineHeight($lineHeight) {
  line-height: setUnitPx($lineHeight);
  line-height: setUnitRem($lineHeight);
}
